import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index.js'
// auth
import Login from "@/views/auth/Login.vue"

// Dashboard
import Dashboard from "@/views/Dashboard.vue";

// configuration
import Divisions from "@/views/configuration/Divisions.vue"
import SubDivisions from "@/views/configuration/SubDivision.vue"
import PoliceStation from "@/views/configuration/PoliceStation.vue"
import CaseStages from "@/views/configuration/CaseStages.vue"
import PropertyTypes from "@/views/configuration/Propertytypes.vue"
import PropertyDisposalStages from "@/views/configuration/PropertyDisposalStages.vue"


// Muddemaal
import Muddemaal from "@/views/muddemaal/Muddemaal.vue";
import MuddemaalCreate from "@/views/muddemaal/MuddemaalCreate.vue";
import MuddemaalEdit from "@/views/muddemaal/MuddemalEdit.vue";
import MuddemalView from "@/views/muddemaal/MuddemalView.vue";

// users
import UserCreate from '@/views/users/UserCreate.vue';
import UsersList from '@/views/users/UsersList.vue';
import ProfileUpdate from '@/views/users/ProfileUpdate.vue';
import UserLogs from '@/views/users/Logs.vue';
import UserPoliceStations from '@/views/users/Userpolicestation.vue';

//Reports
import AuditReportYearWise from '@/views/reports/AuditReportYearWise.vue';
import AuditReport from '@/views/reports/AuditReport.vue';
import PropertyDetailReport from '@/views/reports/PropertyDetails.vue';
import CasesStatusReport from '@/views/reports/CaseStatusYearWise.vue';

const routes = [
  //Authentication
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },

  //Dashboard 
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },

  // Configuration
  {
    path: '/divisions',
    name: 'Divisions',
    component: Divisions,
    meta: { requiresAdmin: true }
  },
  {
    path: '/sub_divisions',
    name: 'SubDivisions',
    component: SubDivisions,
    meta: { requiresAdmin: true }
  },
  {
    path: '/police_stations',
    name: 'PoliceStation',
    component: PoliceStation,
    meta: { requiresAdmin: true }
  },
  {
    path: '/case_stages',
    name: 'CaseStages',
    component: CaseStages,
    meta: { requiresAdmin: true }
  },
  {
    path: '/property_types',
    name: 'PropertyTypes',
    component: PropertyTypes,
    meta: {requiresAdmin: true}
  },
  {
    path: '/property_disposal_stages',
    name: 'PropertyDisposalStages',
    component: PropertyDisposalStages,
    meta: { requiresAdmin: true }
  },

 // Muddemaal
  {
    path: '/muddemal',
    name: 'Muddemaal',
    component: Muddemaal
  },
  {
    path: '/muddemal_create',
    name: 'MuddemaalCreate',
    component: MuddemaalCreate
  },
  { 
		path: '/muddemal/:muddemal_id/edit', 
    name:'MuddemaalEdit', 
		component:MuddemaalEdit, 
	},
  { 
		path: '/muddemal/:muddemal_id/view', 
    name:'MuddemalView', 
		component:MuddemalView, 
	},


  // User Management
  {
    path: '/user_create',
    name: 'UserCreate',
    component: UserCreate,
    meta: { requiresAdmin: true }
  },

  {
    path: '/user_update/:user_id',
    name: 'UserUpdate',
    component: UserCreate,
    meta: { requiresAdmin: true }
  },

  {
    path: '/users',
    name: 'UsersList',
    component: UsersList,
    meta: { requiresAdmin: true }
  },
  {
    path: '/profile_update',
    name: 'ProfileUpdate',
    component: ProfileUpdate
  },
  {
    path: '/logs',
    name: 'UsersLogs',
    component: UserLogs
  },
  {
    path: '/Userpolicestation/:user_id',
    name: 'Userpolicestations',
    component: UserPoliceStations,
    meta: { requiresAdmin: true }
  },
  {
    path: '/audit_report',
    name: 'AuditReport',
    component: AuditReport
  },
  {
    path: '/audit_report_year_wise',
    name: 'AuditReportYearWise',
    component: AuditReportYearWise
  },
  {
    path: '/property_details_report',
    name: 'PropertyDetailReport',
    component: PropertyDetailReport
  },
  {
    path: '/cases_status_report',
    name: 'CasesStatusReport',
    component: CasesStatusReport
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the route requires admin access
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters?.user?.is_admin == false && store.state.user != null) {
      store.dispatch("error", "You dont have permission to access this url");
      next(from.path);
    } else {
      next(); // Proceed to the admin route
    }
  } else {
    next(); // Allow access to other routes
  }
});

export default router
